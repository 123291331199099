import Services from "./Services";
import Contact from "./Contact";
import Banner from "./Banner";
import Partners from "./Partners";
import React from "react";
import RandomCase from "./RandomCase";

export default function Main() {
    return (
        <>
            <Banner/>
            <Services/>
            <RandomCase/>
            <Partners/>
            <Contact/>
        </>
    );
}
