export default function Services() {

    return <section className="container py-10 services">
        <h1 className="fw-bold mb-3 mb-lg-5 text-center" id="nossos-servicos">Nossos serviços</h1>

        <div className="row">

            <div className="col-lg-6 px-lg-0 px-5 text-lg-start text-center my-3 my-lg-0">
                <div className="d-flex justify-content-center">
                    <img src="/images/services/custom-systems.svg" className="img-item-service"/>
                </div>
                <h4 className="fw-bold mb-3">Sistemas personalizados</h4>
                <p>
                    Desenvolvemos projetos personalizados para atender com precisão os processos específicos da sua empresa.
                </p>
            </div>

            <div className="col-lg-6 px-lg-0 px-5 text-lg-start text-center my-3 my-lg-0">
                <div className="d-flex justify-content-center">
                    <img src="/images/services/mobile.svg" className="img-item-service"/>
                </div>
                <h4 className="fw-bold mb-3">Aplicativos</h4>
                <p>
                    Ajudando a trasformar seus sonhos em soluções corporativas e competitivas no mercado.
                </p>
            </div>

        </div>
    </section>
}
