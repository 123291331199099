import {FaAngleUp} from "react-icons/fa";

export default function GoTop() {
    return (
        <div className="d-none d-md-block d-lg-block">
            <a href="#top" className="go-top">
                <FaAngleUp size={30}/>
            </a>
        </div>
    );
}
