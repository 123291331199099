import React from 'react';
import ReactDOM from 'react-dom/client';
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Main from "./components/Main";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Cases from "./components/Cases";
import AboutUs from "./components/AboutUs";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Header/>
            <main>
                <Routes>
                    <Route path="/" element={<Main/>}/>
                    <Route path="/aboutUs" element={<AboutUs/>}/>
                    <Route path="/cases" element={<Cases/>}/>
                </Routes>
            </main>
            <Footer/>
        </BrowserRouter>
    </React.StrictMode>
);
