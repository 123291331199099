export default function Banner() {
    return <section className="banner container-fluid bg-dark text-white py-5">
        <div className="container">
            <div className="row d-flex align-items-center py-5">
                <div className="col-12 col-lg-6 text-start text-lg-end">
                    <h1>
                        <span className="line-1">Nós utilizamos</span>
                        <br/>
                        <strong className="text-uppercase text-danger line-2">tecnologia</strong>
                        <br/>
                        <div className="line-3">
                            para <strong className="text-uppercase text-danger">decolar</strong>
                        </div>
                        <span className="line-4">seu negócio</span>
                    </h1>
                </div>
                <div className="col-12 col-lg-6">
                    <img src="/images/banner/rocket.svg" alt="Nós utilizamos tecnologia para melhorar seu negócio" className="img-fluid"/>
                </div>
            </div>
        </div>
    </section>;
}
