import items from "../../Cases/items.json";
import {Link} from "react-router-dom";

export default function RandomCase() {

    const item = items[Math.floor(Math.random() * items.length)];
    const image = item.images[0];

    return (
        <section className="container py-10 text-center">
            <h1 className="fw-bold text-center mb-3">Clique abaixo e veja nossos casos de sucesso</h1>
            <Link to="Cases">
                <img src={image} className="img-fluid w-50" alt={item.title}/>
            </Link>
        </section>
    );
}
