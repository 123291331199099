import {useState} from "react";

export default function Contact() {

    const [state, setState] = useState({
        nome: undefined,
        telefone: undefined,
    });

    const handleSubmit = (e: any) => {
        window.open("https://wa.me/554491212290?text=Hey%21+Vamos+conversar%3F+Eu+gostaria+de+contar+sobre+meu+projeto.", "_blank", "noopener noreferrer");

        e.stopPropagation();
    };

    const handleChange = (e: any) => {
        const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
        setState({
            ...state,
            [e.target.name]: value
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <section className="container-fluid py-10 bg-dark text-white">
                <div className="container py-10">
                    <div className="text-center px-10">
                        <h1 className="display-6 mb-3" id="contato">
                            Estamos sempre interessados em ouvir sobre o seu projeto, por isso seu contato é muito importante.
                        </h1>
                        <p className="lead">
                            Nos conte sobre sua ideia:
                        </p>

                        <div className="mt-3">
                            <input type="text" className="form-control" id="nome" name="nome" required
                                   placeholder="Qual seu nome?*"
                                   value={state.nome} onChange={handleChange}/>
                        </div>
                        <div className="mt-3">
                            <input type="text" className="form-control" id="telefone" name="telefone" required
                                   placeholder="Whats App?*"
                                   value={state.telefone} onChange={handleChange}/>
                        </div>

                        <div className="mt-3">
                            <button className="btn btn-success btn-contato-whatsapp px-5">
                                <span className="fa-brands fa-whatsapp me-3"></span>
                                Inicie uma conversa
                            </button>
                        </div>
                    </div>
                </div>

            </section>
        </form>
    )
}
