import GoTop from "../Main/GoTop";

export default function Footer() {
    return <footer>
        <section className="footer-area text-center text-lg-start">
            <div className="container">
                <div className="d-lg-flex justify-content-between">
                    <div className="col-lg-4">
                        <img src="logo.png" className="mb-4" height={60}/>
                        <p>
                            Transformando sistemas em soluções
                        </p>
                    </div>
                    <div className="col-lg-3">
                    </div>
                    <div className="col-lg-4">
                        <h4 className="mb-3">Contato</h4>
                        <ul className="footer-nav-links">
                            <li><strong>Endereço: </strong> Av. Carneiro Leão, 294 - Sala 401, Maringá/PR</li>
                            <li><strong>Telefone: </strong> 44 99121-2290 / 44 99171-1612</li>
                            <li><strong>E-mail: </strong> contato@ironbug.com.br</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section className="copyright-area">
            <div className="container">
                © {new Date().getFullYear()} - Ironbug Soluções Tecnológicas Ltda
            </div>
        </section>
        <GoTop/>
    </footer>
}


